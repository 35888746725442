import React, { useState } from 'react';
import { Grid, Fab } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PurposeContentList from './PurposeList';
import './styles.css';

const COMPANY_PURPOSE = [
  {
    title: 'MISSION',
    concept: 'Our experience in software development enables us to put together highly competitive teams that combine know-how and culture, for the benefit of our customers in an ever growing win-win relationship.',
    nextTitle: '',
    selected: true,
  },
  {
    title: 'VISION',
    concept: 'We will change the global market of the software industry by delivering quality business solutions that apply state-of-the-art technologies and an optimal software development process with highly competitive prices.',
    nextTitle: '',
    selected: false,
  },
];

const fillSliderTitles = () => COMPANY_PURPOSE.map((purpose, i) => {
  if (i < COMPANY_PURPOSE.length - 1) {
    const { title } = COMPANY_PURPOSE[i + 1];
    return { ...purpose, nextTitle: title };
  }
  if (i === COMPANY_PURPOSE.length - 1) {
    const { title } = COMPANY_PURPOSE[0];
    return { ...purpose, nextTitle: title };
  }
  return null;
}).filter((item) => item !== null);

const [clickUp, clickDown] = ['up', 'down'];

export default () => {
  const [purposes, setPurposes] = useState(fillSliderTitles());
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isEven, setIsEven] = useState(true);

  const clickSelectedItem = ({ index, type }) => {
    let counter;
    if (index !== undefined) {
      counter = index;
    } else {
      counter = selectedIndex;
    }

    if (type && type === clickUp && selectedIndex > 0) {
      counter -= 1;
    }
    if (type && type === clickDown && selectedIndex < purposes.length - 1) {
      counter += 1;
    }
    const evenOdd = counter % 2 === 0;
    const newPurposes = purposes.map((purpose, i) => {
      if (i === counter) {
        return { ...purpose, selected: true };
      }
      return { ...purpose, selected: false };
    });

    setSelectedIndex(counter);
    setPurposes([...newPurposes]);
    setIsEven(evenOdd);
  };

  const isFirstPurposeSelected = selectedIndex === 0;
  const isLastPurposeSelected = selectedIndex === purposes.length - 1;

  return (
    <>
      <Grid container className={`about-us-slider-container ${isEven ? 'is-even' : 'is-odd'}`}>
        <Grid item xs={12} sm={12} className="slide-up">
          <Fab
            color="secondary"
            aria-label="up"
            onClick={() => clickSelectedItem({ type: clickUp })}
            className={isFirstPurposeSelected ? 'disabled' : ''}
          >
            <ArrowUpwardIcon />
          </Fab>
        </Grid>
        <PurposeContentList items={purposes} isEven={isEven} />
        <Grid item xs={12} sm={12} className="slide-down">
          <Fab
            color="secondary"
            aria-label="down"
            onClick={() => clickSelectedItem({ type: clickDown })}
            className={isLastPurposeSelected ? 'disabled' : ''}
          >
            <ArrowDownwardIcon />
          </Fab>
        </Grid>
      </Grid>
    </>
  );
};
