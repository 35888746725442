import React from 'react';
import { Grid } from '@material-ui/core';
import AboutUsInfoItem from './AboutUsItem';
import './styles.css';

const AboutUsInfoList = ({ aboutList }) => (
  <Grid container className="more-about-list-content">
    {
        aboutList.map(({
          img, quantity, title, sizeMS,
        }, i) => (
          <AboutUsInfoItem
            img={img}
            quantity={quantity}
            title={title}
            sizeMS={sizeMS}
            key={i}
          />
        ))
      }
  </Grid>
);

export default React.memo(AboutUsInfoList);
