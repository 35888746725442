import React from 'react';
import { Typography } from '@material-ui/core';
import './styles.css';

const compareSelectedValueMemo = (prevProp, nextProp) => {
  const { selected: selectedPrevious } = prevProp;
  const { selected: selectedNext } = nextProp;
  if (selectedPrevious === selectedNext) {
    return true;
  }
  return false;
};

const PurposeContentItem = ({
  title, concept, nextTitle, selected, isEven,
}) => (

  <div className="purpose-content-item-grid">
    {selected
      ? (
        <div className={`purpose-content-item ${!isEven ? 'odd-color' : ''}`}>
          <Typography variant="h5" className="purpose-item-title">
            {' '}
            {title}
            {' '}
          </Typography>
          <Typography variant="body1" className="purpose-item-concept">
            {' '}
            {concept}
            {' '}
          </Typography>
          <Typography variant="h5" className={`${!selected ? '' : ''} purpose-item-next-title`}>
            {' '}
            {nextTitle}
            {' '}
          </Typography>
        </div>
      )
      : null}
  </div>
);

export default React.memo(PurposeContentItem,
  (prevProp, nextProp) => compareSelectedValueMemo(prevProp, nextProp));
