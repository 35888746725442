import React from 'react';
import { Grid } from '@material-ui/core';
import PurposeContentItem from './PurposeContentItem';
import './styles.css';

const PurposeContentList = ({ items, isEven }) => (
  <Grid item xs={12} sm={12} className="purpose-content-list">
    {
        items.map(({
          title, concept, nextTitle, selected,
        }, i) => (
          <PurposeContentItem
            title={title}
            concept={concept}
            nextTitle={nextTitle}
            selected={selected}
            key={i}
            isEven={isEven}
          />
        ))
      }
  </Grid>
);

export default React.memo(PurposeContentList);
