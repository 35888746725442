import React from 'react';
import { Grid, Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Link from 'gatsby-link';
import AboutUsSlider from '../components/About/AboutUsSlider';
import AboutUsInfoList from '../components/About/AboutUsInfoList';
import imgCustomeRetention from '../assets/customer-retention.png';
import imgCustomers from '../assets/customers.png';
import imgInBussiness from '../assets/in-bussiness.png';
import imgStaff from '../assets/staff.png';

import { SEO } from '../shared/seo';
import Header from '../layout/Header/header';
import Footer from '../layout/Footer/footer';

import '../styles/aboutStyles.css';

const overrides = {
  title: 'About | Nearshore Code',
  description: 'We put together highly competitive teams that combine know-how and culture, for the benefit of our customers.',
  openGraph: {
    type: 'website',
    url: 'https://nearshorecode.com/about',
    title: 'About | Nearshore Code',
    description: 'We put together highly competitive teams that combine know-how and culture, for the benefit of our customers.',
    image: 'https://nearshorecode.com/facebookimage.png',
  },
  twitter: {
    card: 'summary_large_image',
    url: 'https://nearshorecode.com/about',
    title: 'About | Nearshore Code',
    description: 'We put together highly competitive teams that combine know-how and culture, for the benefit of our customers.',
    image: 'https://nearshorecode.com/twitterimage.png',
  },
};

const ABOUT_US_LIST = [
  {
    img: imgCustomers, quantity: '15+', title: 'CUSTOMERS', sizeMS: 4,
  },
  {
    img: imgStaff, quantity: '35+', title: 'FULL TIME STAFF', sizeMS: 4,
  },
  {
    img: imgInBussiness, quantity: '4+ YEARS', title: 'IN BUSSINESS', sizeMS: 4,
  },
  {
    img: imgCustomeRetention, quantity: '100%', title: 'CUSTOMER RETENTION', sizeMS: 12,
  },
];

const About = () => (
  <div>
    <SEO overrides={overrides} />
    <Header />
    <div className="more-about-us-modal">
      <Grid container className="more-about-us-container">
        <Grid item xs={12} sm={8} className="more-about-us-list">
          <AboutUsInfoList aboutList={ABOUT_US_LIST} />
        </Grid>
        <Grid item xs={12} sm={4} className="more-about-us-slider">
          <AboutUsSlider />
        </Grid>
      </Grid>
      <div className="slide-section-close">
        <Link to="/">
          <Fab className="slide-item-close" size="small" color="secondary" aria-label="close">
            <CloseIcon />
          </Fab>
        </Link>
      </div>
    </div>
    <Footer />
  </div>
);

export default About;
