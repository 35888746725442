import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './styles.css';

const AboutUsInfoItem = ({
  img, quantity, title, sizeMS,
}) => (
  <Grid item xs={6} sm={sizeMS} className="more-about-item">
    <img src={img} alt={title} />
    <Typography variant="h4">
      {' '}
      {quantity}
      {' '}
    </Typography>
    <Typography variant="h6">
      {' '}
      {title}
      {' '}
    </Typography>
  </Grid>
);

export default React.memo(AboutUsInfoItem);
